<template>
    <div class="w-full h-full px-5 pt-5">
      <div class="flex flex-col">
        <div class="flex justify-start items-center gap-8">
          <h1 class="text-xl text-left font-extrabold">
            Documents
          </h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
  
        <div v-if="summary" class="w-full flex flex-row justify-between gap-3 mt-6 mb-3">
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                  <icon icon-name="icon-folder" size="xsm" />
              </div>
              <div>
                  <p class="text-base font-semibold text-romanSilver">Folder Name</p>
                  <p class="text-sm text-jet font-semibold">{{ summary.folderName ? summary.folderName : "-"}}</p>
              </div>
          </Card>   
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                  <icon icon-name="solar-user-icon" size="xsm" />
              </div>
              <div>
                  <p class="text-base font-semibold text-romanSilver">Group</p>
                  <p class="text-sm text-jet font-semibold">{{ summary.groupName ? summary.groupName : "-"  }}</p>
              </div>
          </Card>  
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                  <icon icon-name="user" size="xsm" />
              </div>
              <div>
                  <p class="text-base font-semibold text-romanSilver">Owner</p>
                  <p class="text-sm text-jet font-semibold">
                    {{ summary.owner ? summary.owner.fname + ' ' + summary.owner.lname : '-'  }}
                  </p>
              </div>
          </Card>  
        </div> 
  
        <div v-if="summary" class="w-full flex flex-row justify-between gap-3 mb-6">
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                <img
                  src="@/assets/images/files-multi.png"
                  style="width: 24px; height: 24px"
                />
              </div>
              <div>
                  <p class="text-base font-semibold text-romanSilver">Files</p>
                  <p class="text-sm text-jet font-semibold uppercase">
                    {{ summary.summaryTotalFiles ? summary.summaryTotalFiles.totalNumberOfFiles : "-" }}
                  </p>
              </div>
          </Card>   
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                <img
                  src="@/assets/images/home-storage.png"
                  style="width: 24px; height: 24px"
                />
              </div>
              <div>
                <p class="text-base font-semibold text-romanSilver">Storage</p>
                <p class="text-sm text-jet font-semibold uppercase">
                  {{ summary.summaryTotalFiles ? kbToSize(summary.summaryTotalFiles.totalSize) : "-" }}
                </p>
              </div>
          </Card>  
          <Card class="card flex flex-row justify-start items-center gap-3 p-3">
              <div>
                <img
                  src="@/assets/images/schedule-school-date-time.png"
                  style="width: 24px; height: 24px"
                />
              </div>
              <div>
                  <p class="text-base font-semibold text-romanSilver">Last Modified</p>
                  <p class="text-sm text-jet font-semibold uppercase">
                    {{ summary.lastModified ? $DATEFORMAT(new Date(summary.lastModified.updatedAt), "MMMM dd, yyyy")  : "-" }}
                  </p>
              </div>
          </Card>  
        </div>
        
        <div class="line"/>

        <div class="h-full">
          <div>
            <loader v-if="isloading" size="xxs" :loader-image="false" />
            <div v-else>
              <Card class="p-5">
                <template v-slot:footer>
                  <card-footer
                      @reload="reload()"
                      @sortType="handleSort($event)"
                      @option="handleOptionSelect($event)"
                      @searchResult="handleSearch($event)"
                      @actionModal="viewFilterTable($event)"
                      search-placeholder="Search"
                      :show-sort-filter="false"
                  />
                </template>
              </Card>
  
              <sTable
                :headers="employeesHeader"
                :items="employees"
                style="width: 100%"
                :loading="isloading"
                aria-label="employee table"
                class="w-full"
                id="printMe"
                :pagination-list="allEmployeesMeta"
                @page="handlePage($event)"
                @itemsPerPage="handleItemsPerPage($event)"
                page-sync
                :has-number="false"
                :has-checkbox="true"
                v-if="employees.length > 0"
              >
                <template v-slot:item="{ item }">
                    <template v-if="item.fname">
                        <div class="flex flex-row items-center">
                          <div>
                            <img v-if="item.data.photo" :src="item.data.photo" alt="profile pic" class="w-8 h-8 rounded" />
                            <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                              {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                            </div>
                          </div>
                          <div class="ml-2 flex flex-col justify-between">
                              <span class="font-bold text-sm text-darkPurple">
                                {{ item.data.fname ? item.data.fname + ' ' + item.data.lname : '-' }}
                              </span>
                              <span class="font-black text-blueYonder uppercase text-10">
                                {{ item.data.userDesignation ? item.data.userDesignation.name : '-' }}
                              </span>
                          </div>
                        </div>
                    </template>
                    <template v-else-if="item.orgFunction">
                        <div class="flex flex-col">
                            <template class="text-darkPurple text-sm font-normal">
                              {{ item.data.orgFunction ? item.data.orgFunction.name : '-' }}
                            </template>
                        </div>
                    </template>
                    <template v-else-if="item.numberOfFiles">
                      <div class="flex flex-col">
                        <template class="text-darkPurple">
                            <div>
                              {{ item.data.numberOfFiles ? item.data.numberOfFiles : '-' }} files
                            </div>
                        </template>
                        <div class="text-10 ">
                            <span class="font-normal text-blueYonder">
                              {{ item.data.totalSize ?  kbToSize(item.data.totalSize) : '-' }}
                            </span>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.documentLastModified">
                      <div class="flex flex-col">
                          <template class="text-darkPurple">
                              <div>
                                {{ $DATEFORMAT(new Date(item.data.documentLastModified.updatedAt), "MMMM dd, yyyy") }}
                              </div>
                          </template>
                          <div class="text-10 ">
                              <span class="font-normal text-blueYonder">
                                  10:00am
                              </span>
                          </div>
                      </div>
                    </template>
                    <span v-else-if="item.userId" class="text-right">
                        <Menu left top="-150" margin="24" class="my-2 p-0">
                            <template v-slot:title>
                                <icon icon-name="more_icon" size="xs" />
                            </template>
                            <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                                <div class="w-full"
                                  @click="$router.push({
                                      name: 'ViewEmployeeDocs',
                                      params: { id: item.data.userId }
                                  })"
                                >
                                    <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                                        <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                                        <p class="font-normal text-sm text-darkPurple">View Employee</p>
                                    </div>
                                </div>
                            </div>
                        </Menu>
                    </span>
                  </template>
              </sTable>
              <div class="w-full h-64 flex flex-col justify-center items-center gap-5" v-if="employees.length === 0">
                  <img class="wfull" :src="require('@/assets/images/emptyFolders.svg')" alt="not_found">
                  <p class="">There are no employees in your account yet.</p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>     
  </template>
  
  <script>
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import STable from "@scelloo/cloudenly-ui/src/components/table";
    
    export default {
      name: "ViewFolder",
      components: {
        Breadcrumb,
        STable,
        Card: () => import('@/components/Card'),
        CardFooter: () => import("@/components/CardFooter"),
        Menu: () => import("@/components/Menu"),
      },
      data() {
        return {
          isloading: false,
          folderSummary: {},
          allEmployees: [],
          allEmployeesMeta: {},
          sortType: null,
          itemsPerPage: null,
          numberOfPage: null,
          searchWord: null,
        };
      },
      computed: {
        breadcrumbs() {
          return [
            {
              disabled: false,
              text: "Documents",
              href: "OrganisationCompanyDocuments",
              id: "DocumentMgt",
            },
            {
              disabled: false,
              text: "Employee Documents",
              href: "EmployeeDocuments",
              id: "EmpDocuments",
            },
            {
              disabled: false,
              text: "View Folder",
              href: "ViewFolder",
              id: "ViewFolder",
            },
          ];
        },
        employeesHeader(){ 
          return [
              { title: "Employee Name", value: "fname" },
              { title: "Function", value: "orgFunction" },
              { title: "File Size (MB)", value: "numberOfFiles" },
              { title: "Last Modified", value: "documentLastModified" },
              { title: "", value: "userId", image: true }
          ];
        },
        employees(){ 
          return this.allEmployees;
        },
        summary(){ 
          return this.folderSummary;
        }
      },
      methods: {
        queryUpdate() {
            
        },
        handleSort(sortType) {
            this.sortType = sortType;
            this.queryUpdate();
        },
        handleItemsPerPage(itemNo) {
            this.itemsPerPage = itemNo;
            this.queryUpdate();
        },
        handlePage(pageNumber) {
            this.numberOfPage = pageNumber;
            this.queryUpdate();
        },
        handleSearch(searchWord) {
            if (searchWord) {
                this.searchWord = searchWord;
                this.queryUpdate();
            } else {
                this.searchWord = null;
                this.queryUpdate();
            }
        },
        handleItemsPerPage(itemNo) {
            this.itemsPerPage = itemNo;
            this.queryUpdate();
        },
        handlePage(pageNumber) {
            this.numberOfPage = pageNumber;
            this.queryUpdate();
        },
        async viewFolder(){
          this.isloading = true;
          const folderId = this.$route.params.id;
         
          try {
              const { data } = await this.$_getAFolder(folderId);
              this.folderSummary = data.data.summary;
              this.allEmployees = data.data.emp;
              this.allEmployeesMeta = data.meta;
              this.isloading = false;
          } catch (error) {
              this.isloading = false;
          }
        },
        kbToSize(kb) {   
          const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
          if (kb === 0) return '0 KB';
          if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; 
              const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
              const sizeIndex = Math.min(i, sizes.length - 1);
          if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
          return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
        }
      },
      async mounted() {
        await this.viewFolder();
      }
    };
  </script>
  
  <style scoped>
  .card{
      width: calc(100% / 3) !important;
      height: 68px !important;
      border: 1px solid #00000029 !important;
      border-radius: 8px !important;
  }
  .line{
    border: 1px solid #00000029;
    width: 100%;
    height: 0;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0;
  }
  .text-10{
    font-size: 10px !important;
  }
  </style>  